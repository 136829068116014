import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import { useToast } from "@chakra-ui/react";
import convertToBase64 from "../../../helper/convert";
import axios from "axios";
import profile from "../../../../src/assets/img/profile.png";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  ErrorMessage,
  Field,
  Form,
  Formik,
  useFormik,
  useFormikContext,
} from "formik";
import * as Yup from "yup";
import BASE_URL from "../../../baseUrl";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Content = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const para = useParams();
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState("Buyer");
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // Step 2

  const [url, setUrl] = useState();
  const [verify, setVerify] = useState(false);
  const [code, setCode] = useState("");
  const [id, setId] = useState("");
  const [run, setRun] = useState(0);
  const countryCode = [
    "+66", // Thailand
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+966", // Saudi Arabia
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];

  const registerUser = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };
  let CurrentUrl = window.location.href;
  const initialValues = {
    name: "",
    email: "",
    selectorValue: "",
    phonenumber: "",
    password: "",
    user: "buyer",
    selectorValueCode: "",
    image: null,
  };
  const validationSchemaRegister = Yup.object().shape({
    name: Yup.string().required(t("Name is Required")),
    selectorValue: Yup.string().required(t("Please Select Option")),
    selectorValueCode: Yup.string().required(t("Please Select Country Code")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t(`Email is required`)),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, t("Phone number must only contain digits"))
      .min(10, t("Phone number is too short"))
      .max(15, t("Phone number is too long"))
      .required(t("Phone number is required")),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
        t("Password must contain")
      )
      .min(8, t("Password must be at least 8 characters long"))
      .required(t("Password is required")),
    user: Yup.string().required(t("Please Select")),
    image: Yup.mixed().required(t("image is required")),
  });

  const signup = async (formik) => {
    setIsLoading(true); // Start loading indicator
    const {
      selectorValue,
      email,
      name,
      password,
      phonenumber,
      selectorValueCode,
      user,
      image,
    } = formik.values;

    if (!selectorValue) {
      toast({
        title: "Error",
        duration: 900,
        isClosable: true,
      }); // Show toaster error
      setIsLoading(false);
      return;
    }
    // Validation for fields
    if (selectorValue === "email") {
      if (!email || !name || !password || !user || !image) {
        setIsLoading(false); // Stop loading indicator
        toast({
          title: "Error",
          status: "error",
          description: "Please fill out all required fields!",
          duration: 900,
          isClosable: true,
        }); // Show toaster error
        return; // Exit the function early
      }
    } else if (selectorValue === "phonenumber") {
      if (
        !phonenumber ||
        !name ||
        !password ||
        !user ||
        !image ||
        !selectorValueCode
      ) {
        setIsLoading(false); // Stop loading indicator
        toast({
          title: "Error",
          status: "error",
          description: "Please fill out all required fields!",
          duration: 900,
          isClosable: true,
        }); // Show toaster error // Show toaster error
        return; // Exit the function early
      }
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("name", name);
    if (selectorValue === "phonenumber") {
      formData.append("phone", `+${phonenumber}`); // Ensure the `+` sign is retained
    } else if (selectorValue === "email") {
      formData.append("email", email);
    }
    formData.append("password", password);
    formData.append("user", user);
    formData.append("pic", image);

    try {
      // API call
      const response = await axios.post(`${BASE_URL}/register`, formData);

      if (
        response.data.Msg === "This User Is Already Exits" ||
        response.data.Msg === "Please Fill Out All Fields"
      ) {
        setError(true);
        toast({
          title: "Error",
          description: "Try Again",
          duration: 900,
          isClosable: true,
        }); // Show toaster error // Show toaster for error message
      } else {
        setVerify(true);
        setId(response.data.user?.id);
        localStorage.setItem("userRegistor", JSON.stringify(response.data));
        toast({
          title: "Success",
          status: "success",
          // description:"Please fill out all required fields!",
          duration: 900,
          isClosable: true,
        }); // Show toaster error // Show success toaster
      }
    } catch (error) {
      // toast.error("An error occurred during registration."); // Handle API errors
    } finally {
      setIsLoading(false); // Stop loading indicator after API call
    }
  };

  const postDataVerify = async () => {
    setIsLoading(true); // Start loading indicator

    if (!code || code === undefined) {
      toast({
        title: "Error",
        description: "Enter your username.",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }

    await axios
      .post(`${BASE_URL}/register-verify`, {
        code: code,
      })
      .then((res) => {
        res.data.Msg === "register" ? registerUser(res.data) : setError(true);
        setErrorMsg(res.data.Msg);
        setIsLoading(false);
      });
  };
  const images = [
    {
      img: "assets/img/coming-soon/1.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/2.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/3.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  };
  const onUpload = async (e, formik) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await convertToBase64(file); // Ensure this function is correctly implemented
        setUrl(base64); // If you are using this to show a preview
        formik.setFieldValue("image", file);
        formik.setFieldTouched("image", true, false);
      } catch (error) {
        formik.setFieldError("image", "Error converting file");
      }
    } else {
      formik.setFieldError("image", "A file is required");
    }
  };
  // In your render or JSX:
  const [selectorValue, setSelectorValue] = useState("");
  const [selectorValueCode, setCountryCode] = useState("");
  const handleChange = (e) => {
    setSelectorValue(e.target.value);
  };
  const handleChangeCode = (e) => {
    setCountryCode(e.target.value);
  };
  const continueWithGoogle = async () => {
    const resposne = await fetch(`${BASE_URL}/auth/google`);
    const data = await resposne.json();
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="acr-auth-container">
      <div className="acr-auth-content">
        {verify ? (
          <form
            className="py-1"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            // enctype="multipart/form-data"
            // method="post"
          >
            <div className="auth-text">
              <h3>{t("createAnAcresAccount")}</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>
            <div className="profile flex justify-center py-4">
              <label htmlFor="profile">
                <img
                  src={url || profile}
                  className="border-4 border-gray-100 w-[135px] rounded-full shadow-lg cursor-pointer"
                  alt="avatar"
                />
              </label>
              <input
                onChange={onUpload}
                type="file"
                id="profile"
                name="pic"
                style={{ display: "none" }}
              />
            </div>
            <div className="form-group">
              <label>{t("Verification Code")}</label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="form-control form-control-light"
                placeholder={t("Enter your verification code")}
                name="code"
              />
            </div>
            <button
              type="Submit"
              className="btn-custom secondary btn-block"
              onClick={() => postDataVerify()}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                t("Verify")
              )}
            </button>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {error ? (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    backgroundColor: "#FF3131",
                    color: "white",
                    padding: "10px 20px 10px 20px",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                >
                  <span>{error ? `${t(errorMsg)}` : ""}</span>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      border: "white 2px solid",
                      borderRadius: "30px",
                      width: "40px",
                      backgroundColor: "#FF3131",
                      height: "40px",
                    }}
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      x
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <p className="text-center mb-0">
              {t("alreadyHaveAccount")} <Link to="/login">{t("login")}</Link>{" "}
            </p>
          </form>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaRegister} // Note the changes here
            onSubmit={signup}
            validateOnChange
          >
            {(formik) => {
              return (
                <Form className="py-1" onSubmit={formik.handleSubmit}>
                  <div className="auth-text">
                    <h3>{t("createAnAcresAccount")}</h3>
                  </div>
                  <div className="profile flex justify-center py-4">
                    <label htmlFor="profile">
                      <img
                        src={url || profile}
                        className="border-4 border-gray-100 w-[135px] rounded-full shadow-lg cursor-pointer"
                        alt="avatar"
                      />
                    </label>
                    <input
                      type="file"
                      id="profile"
                      onChange={(e) => {
                        if (e.target.files.length > 0) {
                          onUpload(e, formik);
                        }
                      }}
                      style={{ display: "none" }}
                    />
                  </div>
                  <ErrorMessage
                    name="image"
                    component="div"
                    className="error-message text-danger"
                  />
                  <div>
                    <div className="form-group">
                      <label>{t("username")}</label>
                      <Field
                        type="text"
                        // value={name}
                        // onChange={(e) => setName(e.target.value)}
                        className="form-control form-control-light"
                        placeholder={t("username")}
                        name="name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <label>{t("selectEmailOrPhoneNumber")}</label>
                      <Field
                        as="select" // Use "as" prop to specify the HTML element type
                        className="form-control form-control-light"
                        name="selectorValue"
                      >
                        <option
                          value=""
                          label={t("selectEmailOrPhoneNumber")}
                        />
                        <option value="email" label={t("emailAddress")} />
                        <option value="mobile" label={t("mobileNumber")} />
                      </Field>
                      <ErrorMessage
                        name="selectorValue"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>

                    {formik.values.selectorValue === "email" && (
                      <div className="form-group">
                        <label>{t("emailAddress")}</label>

                        <Field
                          type="text"
                          className="form-control form-control-light"
                          placeholder={t("emailAddress")}
                          name="email"
                        />
                        {formik.values.selectorValue === "email" ? (
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error-message text-danger"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {formik.values.selectorValue === "mobile" && (
                      <div className="form-group">
                        <label>{t("mobileNumber")}</label>
                        <div style={{ display: "flex", width: "100%" }}>
                          <div style={{ width: "100%" }}>
                            <PhoneInput
                              country={formik.values.selectorValueCode || "th"} // Default to Pakistan's country code
                              value={formik.values.phonenumber}
                              onChange={(value, country) => {
                                formik.setFieldValue("phonenumber", value);
                                formik.setFieldValue(
                                  "selectorValueCode",
                                  country.dialCode
                                );
                              }}
                              inputStyle={{ width: "100%", height: "45px" }}
                            />
                          </div>

                        </div>
                        {formik.values.selectorValue === "mobile" ? (
                          <ErrorMessage
                            name="phonenumber"
                            component="div"
                            className="error-message text-danger"
                          />
                        ) : (
                          ""
                        )}

                        <ErrorMessage
                          name="selectorValueCode"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    )}
                    <div className="relative w-full">
                      <Field
                        type={showPassword ? "text" : "password"}
                        className="form-control form-control-light w-full pr-10" // Add padding-right to make space for the icon
                        placeholder={t("password")}
                        name="password"
                      />
                      <span
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                        onClick={togglePasswordVisibility}
                        style={{ cursor: "pointer" }}
                      >
                        {showPassword ? <FaEye/> : <FaEyeSlash />}
                      </span>
                    </div>
                    <div className="form-group">
                      <label>{t("user")}</label>
                      <Field
                        as="select"
                        name="user"
                        className="form-control"
                      >
                        <option value="buyer" label={t("buyer")} />
                        <option value="seller" label={t("seller")} />
                        <option value="agent" label={t("agent")} />
                      </Field>
                      <ErrorMessage
                        name="user"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <button
                      type="Submit"
                      className="btn-custom secondary btn-block"
                      onClick={() => signup(formik)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        t("register")
                      )}
                    </button>
                  </div>

                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {error ? (
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                          backgroundColor: "#FF3131",
                          color: "white",
                          padding: "10px 20px 10px 20px",
                          borderRadius: "5px",
                          alignItems: "center",
                        }}
                      >
                        <span>{error ? `${t(errorMsg)}` : ""}</span>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            border: "white 2px solid",
                            borderRadius: "30px",
                            width: "40px",
                            backgroundColor: "#FF3131",
                            height: "40px",
                          }}
                          onClick={() => {
                            setError(false);
                          }}
                        >
                          <p
                            style={{
                              color: "white",
                              alignItems: "center",
                              marginTop: "3px",
                            }}
                          >
                            x
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <p className="text-center mb-0">
                    {t("alreadyHaveAccount")}{" "}
                    <Link to="/login">{t("login")}</Link>{" "}
                  </p>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <div className="acr-auth-bg">
        <Slider className="acr-auth-bg-slider acr-cs-bg-slider" {...settings}>
          {images.map((item, i) => (
            <div key={i}>
              <div
                className="acr-cs-bg-item bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/" + item.img + ")",
                }}
              >
              </div>
            </div>
          ))}
        </Slider>
        <div id="recaptcha-container"></div>
      </div>
    </div>
  );
};

export default Content;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import FormItemInput from "antd/es/form/FormItemInput";
import BASE_URL from "../../../baseUrl";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
function Content() {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Step 2
  const [showPassword, setShowPassword] = useState(false);
  const [newhowPassword, setnewhowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [selectorValue, setSelectorValue] = useState("");
  const [selectorValueCode, setCountryCode] = useState("");
  const [show, setshow] = useState(false);
  const navigate = useNavigate("");
  const { t } = useTranslation();
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setnewhowPassword((prevShowPassword) => !prevShowPassword);
  };
  const countryCode = [
    "+1", // United States
    "+44", // United Kingdom
    "+92", // Pakistan
    "+91", // India
    "+33", // France
    "+49", // Germany
    "+81", // Japan
    "+86", // China
    "+61", // Australia
    "+34", // Spain
    "+39", // Italy
    "+7", // Russia
    "+82", // South Korea
    "+52", // Mexico
    "+55", // Brazil
    "+971", // United Arab Emirates
    "+966", // Saudi Arabia
    "+968", // Oman
    "+20", // Egypt
    "+27", // South Africa
    "+30", // Greece
    "+31", // Netherlands
    "+32", // Belgium
    "+46", // Sweden
    "+47", // Norway
    "+48", // Poland
    "+54", // Argentina
    "+56", // Chile
    "+57", // Colombia
    "+58", // Venezuela
    "+60", // Malaysia
    "+63", // Philippines
    "+64", // New Zealand
    "+65", // Singapore
    "+66", // Thailand
    "+81", // Japan
    "+82", // South Korea
    "+84", // Vietnam
    "+86", // China
    "+90", // Turkey
    "+91", // India
    "+92", // Pakistan
    "+93", // Afghanistan
    "+94", // Sri Lanka
    "+95", // Myanmar
    "+98", // Iran
    "+212", // Morocco
    "+213", // Algeria
    "+216", // Tunisia
    "+218", // Libya
    "+220", // Gambia
    "+221", // Senegal
    "+222", // Mauritania
    "+223", // Mali
    "+224", // Guinea
    "+225", // Ivory Coast
    "+226", // Burkina Faso
    "+227", // Niger
    "+228", // Togo
    "+229", // Benin
    "+230", // Mauritius
    "+231", // Liberia
    "+232", // Sierra Leone
    "+233", // Ghana
    "+234", // Nigeria
    "+235", // Chad
    "+236", // Central African Republic
    "+237", // Cameroon
    "+238", // Cape Verde
    "+239", // Sao Tome and Principe
    "+240", // Equatorial Guinea
    "+241", // Gabon
    "+242", // Republic of the Congo
    "+243", // Democratic Republic of the Congo
    "+244", // Angola
    "+245", // Guinea-Bissau
    "+246", // British Indian Ocean Territory
    "+247", // Ascension Island
    "+248", // Seychelles
    "+249", // Sudan
    "+250", // Rwanda
    "+251", // Ethiopia
    "+252", // Somalia
    "+253", // Djibouti
    "+254", // Kenya
    "+255", // Tanzania
    "+256", // Uganda
    "+257", // Burundi
    "+258", // Mozambique
    "+260", // Zambia
    "+261", // Madagascar
    "+262", // Reunion
    "+263", // Zimbabwe
    "+264", // Namibia
    "+265", // Malawi
    "+266", // Lesotho
    "+267", // Botswana
    "+268", // Eswatini
    "+269", // Comoros
  ];

  const initialValues = {
    email: "",
    selectorValue: "",

    password: "",
    code: "",
    confirmPassword: "",
    selectorValueCode: "",
    phonenumber: "",
  };

  const validationSchemaLogin = Yup.object().shape({
    selectorValue: Yup.string().required(t("Please Select Option")),
    selectorValueCode: Yup.string().required(t("Please Select Country Code")),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, t("Phone number must only contain digits"))
      .min(10, t("Phone number is too short"))
      .max(15, t("Phone number is too long"))
      .required(t("Phone number is required")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t(`Email is required`)),
    code: Yup.string()
      .when("codeFieldVisible", (codeFieldVisible, schema) => {
        return codeFieldVisible
          ? schema.test({
              name: "code-is-number",
              message: "Code must be a number",
              test: (value) => {
                return !isNaN(value);
              },
            })
          : schema;
      })
      .required(t("Code is required")),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
        t(
          "Password must contain at least one uppercase letter, one digit, and one special character"
        )
      )
      .min(8, t("Password must be at least 8 characters long"))
      .required(t("Password is required")),

    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("Passwords must match"))
      .required(t("Confirm Password is required")),
  });
  const [passwordFieldVisible, setPasswordFieldVisible] = useState(false);

  const handleChangeCode = (e, formik) => {
    const { value } = e.target;
    formik.setFieldValue("selectorValueCode", value);
  };

  const Forgot = async (formik) => {
    try {
      setIsLoading(true); // Start loading indicator

      if (show) {
        if (
          (formik.values.email &&
            formik.values.password &&
            formik.values.confirmPassword &&
            formik.values.code &&
            formik.values.password === formik.values.confirmPassword) ||
          (formik.values.phonenumber &&
            formik.values.selectorValueCode &&
            formik.values.password &&
            formik.values.confirmPassword &&
            formik.values.code &&
            formik.values.password === formik.values.confirmPassword)
        ) {
          let bodyContent;
          if (formik.values.selectorValue === "mobile") {
            // If mobile is selected, include both phone and email in the body
            bodyContent = {
              phone:
                formik.values.selectorValueCode + formik.values.phonenumber,
              newPassword: formik.values.password,
              confirmPassword: formik.values.confirmPassword,
              code: formik.values.code,
            };
          } else if (formik.values.selectorValue === "email") {
            // If email is selected, include only email in the body
            bodyContent = {
              email: formik.values.email,
              newPassword: formik.values.password,
              confirmPassword: formik.values.confirmPassword,
              code: formik.values.code,
            };
          }
          const response = await fetch(`${BASE_URL}/forget-password-verify`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            body: JSON.stringify(bodyContent),
          });
          const data = await response.json();
          if (data.message === "Password updated successfully") {
            navigate("/login");
          } else {
            setError(true);
            setErrorMsg(data.message);
          }
        }
      } else {
        if (
          formik.values.email ||
          (formik.values.phonenumber && formik.values.selectorValueCode)
        ) {
          let bodyContent;
          if (formik.values.selectorValue === "mobile") {
            // If mobile is selected, include both phone and email in the body
            bodyContent = {
              phone:
                formik.values.selectorValueCode + formik.values.phonenumber,
            };
          } else if (formik.values.selectorValue === "email") {
            // If email is selected, include only email in the body
            bodyContent = {
              email: formik.values.email,
            };
          }
          const response = await fetch(`${BASE_URL}/forget-password`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              mode: "no-cors",
            },
            body: JSON.stringify(bodyContent),
          });
          const data = await response.json();

          if (
            data.message === "Check Your Email Please" ||
            data.message === "Check Your Phone Please"
          ) {
            setshow(true);
            setPasswordFieldVisible(true);
          } else {
            setError(true);
            setErrorMsg(data.message);
          }
        }
      }

      // Stop loading indicator after API call is complete
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false); // Ensure isLoading is set back to false on error
      console.error("Error during API call", error);
      // Handle the error appropriately
    }
  };

  const images = [
    {
      img: "assets/img/coming-soon/1.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/2.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/3.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  };
  return (
    <div className="acr-auth-container">
      <div className="acr-auth-content">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaLogin}
          onSubmit={Forgot}
          validateOnChange
        >
          {(formik) => {
            return (
              <Form>
                <div className="auth-text">
                  <h3>{t("forgotIntoAcres")}</h3>

                  {/* <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                  </p> */}
                </div>

                <div>
                  <div className="form-group">
                    <label>{t("selectEmailOrPhone")}</label>
                    <Field
                      as="select" // Use "as" prop to specify the HTML element type
                      className="form-control form-control-light"
                      name="selectorValue"
                    >
                      <option value="" label={t("selectEmailOrPhoneNumber")} />
                      <option value="email" label={t("emailAddress")} />
                      <option value="mobile" label={t("mobileNumber")} />
                    </Field>
                    <ErrorMessage
                      name="selectorValue"
                      component="div"
                      className="error-message text-danger"
                    />
                  </div>

                  {formik.values.selectorValue === "email" && (
                    <div className="form-group">
                      <label>{t("emailAddress")}</label>

                      <Field
                        type="text"
                        // value={email}
                        // onChange={(e) => setEmail(e.target.value)}
                        className="form-control form-control-light"
                        placeholder={t("emailAddress")}
                        name="email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  )}
                  {formik.values.selectorValue === "mobile" && (
                    <div className="form-group">
                      <label>{t("mobileNumber")}</label>
                      <PhoneInput
                        country={formik.values.selectorValueCode || "th"} // Default to Pakistan's country code
                        value={formik.values.phonenumber}
                        onChange={(value, country) => {
                          formik.setFieldValue("phonenumber", value);
                          formik.setFieldValue(
                            "selectorValueCode",
                            country.dialCode
                          );
                        }}
                        inputStyle={{ width: "100%", height: "45px" }}
                      />
                      <ErrorMessage
                        name="phonenumber"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                  )}
                  {show && (
                    <>
                      <div className="form-group">
                        <label>{t("Code")}</label>
                        <Field
                          type="text"
                          className="form-control form-control-light"
                          placeholder={t("Code")}
                          name="code"
                        />
                        <ErrorMessage
                          name="code"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                      {/* <div className="form-group">
                        <Field
                        type="password"
                        className="form-control form-control-light"
                        placeholder={t("password")}
                        name="password"
                        />
                        <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message text-danger"
                        />
                      </div> */}
                      <div className="relative w-full mb-4">
                        <label>{t("Newpassword")}</label>
                        <div className="relative">
                          <Field
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-light w-full pr-10" // Ensure the input has padding-right for the icon
                            placeholder={t("password")}
                            name="password"
                          />
                          <span
                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message text-danger mt-2" // Add margin-top to avoid overlap with the input field
                        />
                      </div>
                      <div className="relative w-full mb-4">
                        <label>{t("confirmPassword")}</label>
                        <div className="relative">
                          <Field
                            type={newhowPassword ? "text" : "password"}
                            className="form-control form-control-light w-full pr-10"
                            placeholder={t("confirmPassword")}
                            name="confirmPassword"
                          />
                          <span
                            className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                            onClick={toggleNewPasswordVisibility}
                          >
                            {newhowPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmPassword"
                          component="div"
                          className="error-message text-danger mt-2"
                        />
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    className="btn-custom secondary btn-block"
                    onClick={() => Forgot(formik)}
                    disabled={isLoading}
                    style={{ width: "30%" }}
                  >
                    {isLoading ? (
                      <div className="spinner-border" role="status"></div>
                    ) : !show ? (
                      t("send")
                    ) : (
                      t("Submit")
                    )}
                  </button>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {error ? (
                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "space-between",
                        width: "80%",
                        backgroundColor: "#FF3131",
                        color: "white",
                        padding: "10px 20px 10px 20px",
                        borderRadius: "5px",
                        alignItems: "center",
                      }}
                    >
                      <span>{error ? `${errorMsg}` : ""}</span>
                      <div
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          border: "white 2px solid",
                          borderRadius: "30px",
                          width: "40px",
                          backgroundColor: "#FF3131",
                          height: "40px",
                        }}
                        onClick={() => {
                          setError(false);
                        }}
                      >
                        <p
                          style={{
                            color: "white",
                            alignItems: "center",
                            marginTop: "3px",
                          }}
                        >
                          x
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <div className="acr-auth-bg">
        <Slider className="acr-auth-bg-slider acr-cs-bg-slider" {...settings}>
          {images.map((item, i) => (
            <div key={i}>
              <div
                className="acr-cs-bg-item bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/" + item.img + ")",
                }}
              >
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Content;

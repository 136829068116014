import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import BASE_URL from "../../../baseUrl";
import { useToast } from "@chakra-ui/react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
const Content = () => {
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [verify, setverify] = useState(false);
  const [code, setCode] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // Step 2

  const { t } = useTranslation();
  const toast = useToast();


  const setUser = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };
  const initialValues = {
    email: "",
    selectorValue: "",
    phonenumber: "",
    password: "",
    selectorValueCode: "th",
  };
  const validationSchemaLogin = Yup.object().shape({
    selectorValue: Yup.string().required(t("Please Select Option")),
    selectorValueCode: Yup.string().required(t("Please Select Country Code")),
    email: Yup.string()
      .email(t("Invalid email"))
      .required(t(`Email is required`)),
    phonenumber: Yup.string()
      .matches(/^[0-9]+$/, t("Phone number must only contain digits"))
      .min(10, t("Phone number is too short"))
      .max(15, t("Phone number is too long"))
      .required(t("Phone number is required")),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+=!]).*$/,
        t("Password must contain")
      )
      .min(8, t("Password must be at least 8 characters long"))
      .required(t("Password is required")),
  });


  const postData = async (formik) => {
    setIsLoading(true);
    const { selectorValue, email, phonenumber, selectorValueCode, password } =
      formik?.values;

    // Check for selectorValue and corresponding fields
    if (!selectorValue) {
      setErrorMsg("Please select an option");
      setIsLoading(false);
      return;
    }

    if (selectorValue === "email") {
      if (!email) {
        setIsLoading(false);
        return;
      }
    } else if (selectorValue === "mobile") {
      if (!phonenumber || !selectorValueCode) {
        setIsLoading(false);
        return;
      }
    }

    // Prepare body content based on selectorValue
    let bodyContent = {};
    if (selectorValue === "mobile") {
      bodyContent = {
        phone: `${selectorValueCode}${phonenumber}`,
        password: password,
      };
    } else if (selectorValue === "email") {
      bodyContent = {
        email: email,
        password: password,
      };
    }
    // Check if bodyContent has required fields and selectorValue is present
    if (bodyContent && selectorValue) {
      try {
        const response = await fetch(`${BASE_URL}/login1`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyContent),
        });
        const data = await response.json();
        if (data.Msg === "login") {
          setUser(data);
        } else {
          setError(true);
          setErrorMsg(data.Msg);
        }
      } catch (error) {
        setError(true);
        setErrorMsg("An error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const images = [
    {
      img: "assets/img/coming-soon/1.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/2.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
    {
      img: "assets/img/coming-soon/3.jpg",
      title: "Quote of the day",
      text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
    },
  ];
  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    dots: true,
    dotsClass: "d-flex slick-dots",
  };

  const [selectorValue, setSelectorValue] = useState("");
  const [selectorValueCode, setCountryCode] = useState("");
  const handleChange = (e) => {
    setSelectorValue(e.target.value);
  };

  const handleChangeCode = (e, formik) => {
    const { value } = e.target;

    formik.setFieldValue("selectorValueCode", value);
  };


  const sendOtp = async (formik) => {
    const { selectorValue, email, phonenumber, selectorValueCode, password } =
      formik?.values;
    if (!selectorValue) {
      setErrorMsg("Please select an option");
      setIsLoading(false);
      return;
    }

    if (selectorValue === "email") {
      if (!email) {
        setIsLoading(false);
        return;
      }
    } else if (selectorValue === "mobile") {
      if (!phonenumber || !selectorValueCode) {
        setIsLoading(false);
        return;
      }
    }

    // Prepare body content based on selectorValue
    let bodyContent = {};
    if (selectorValue === "mobile") {
      bodyContent = {
        phone: `${selectorValueCode}${phonenumber}`,
      };
    } else if (selectorValue === "email") {
      bodyContent = {
        email: email,
      };
    }
    if (bodyContent && selectorValue) {
      try {
        const response = await fetch(`${BASE_URL}/sendOtp`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(bodyContent),
        });
        const data = await response.json();
        if (data) {
          setError(false);
          setverify(true);
        }
      } catch (error) {
        setError(true);
        setErrorMsg("An error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };
  const userData = (data) => {
    localStorage.setItem("userInfo", JSON.stringify(data));
    window.location.replace("/");
  };
  const postDataVerify = async () => {
    setIsLoading(true); // Start loading indicator

    if (!code || code === undefined) {
      toast({
        title: "Error",
        description: "Enter code first.",
        status: "error",
        duration: 2000,
        variant: "left-accent",
        position: "top-right",
        isClosable: true,
      });
      return false;
    }

    await axios
      .post(`${BASE_URL}/register-verify`, {
        code: code,
      })
      .then((res) => {
        res.data.Msg === "register" ? userData(res.data) : setError(true);
        setErrorMsg(res.data.Msg);
        setIsLoading(false);
      });
  };
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  return (
    <div className="acr-auth-container ">
      <div className="acr-auth-content ">
        {verify ? (
          <form
            className="py-1"
            onSubmit={(e) => {
              e.preventDefault();
            }}
            // enctype="multipart/form-data"
            // method="post"
          >
            <div className="auth-text">
              <h3>{t("createAnAcresAccount")}</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's
              </p>
            </div>

            <div className="form-group">
              <label>{t("Verification Code")}</label>
              <input
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
                className="form-control form-control-light"
                placeholder={t("Enter your verification code")}
                name="code"
              />
            </div>
            <button
              type="Submit"
              className="btn-custom secondary btn-block"
              onClick={() => postDataVerify()}
              disabled={code === "" ? true : false || isLoading}
            >
              {isLoading ? (
                <div className="spinner-border" role="status"></div>
              ) : (
                t("Verify")
              )}
            </button>

            <div style={{ display: "flex", justifyContent: "center" }}>
              {error ? (
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "80%",
                    backgroundColor: "#FF3131",
                    color: "white",
                    padding: "10px 20px 10px 20px",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                >
                  <span>{error ? `${t(errorMsg)}` : ""}</span>
                  <div
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      justifyContent: "center",
                      border: "white 2px solid",
                      borderRadius: "30px",
                      width: "40px",
                      backgroundColor: "#FF3131",
                      height: "40px",
                    }}
                    onClick={() => {
                      setError(false);
                    }}
                  >
                    <p
                      style={{
                        color: "white",
                        alignItems: "center",
                        marginTop: "3px",
                      }}
                    >
                      x
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            <p className="text-center mb-0">
              {t("alreadyHaveAccount")} <Link to="/login">{t("login")}</Link>{" "}
            </p>
          </form>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaLogin}
            onSubmit={postData}
            validateOnChange
          >
            {(formik) => {
              return (
                <Form>
                  <div className="auth-text">
                    <h3>{t("logIntoAcres")}</h3>
                  </div>

                  <div>
                    <div className="form-group">
                      <label>{t("selectEmailOrPhone")}</label>
                      <Field
                        as="select" // Use "as" prop to specify the HTML element type
                        className="form-control form-control-light"
                        name="selectorValue"
                      >
                        <option
                          value=""
                          label={t("selectEmailOrPhoneNumber")}
                        />
                        <option value="email" label={t("emailAddress")} />
                        <option value="mobile" label={t("mobileNumber")} />
                      </Field>
                      <ErrorMessage
                        name="selectorValue"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    {formik.values.selectorValue === "email" && (
                      <div className="form-group">
                        <label>{t("emailAddress")}</label>

                        <Field
                          type="text"
                          // value={email}
                          // onChange={(e) => setEmail(e.target.value)}
                          className="form-control form-control-light"
                          placeholder={t("emailAddress")}
                          name="email"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    )}
                    {formik.values.selectorValue === "mobile" && (
                      <div className="form-group">
                        <label>{t("mobileNumber")}</label>
                        <PhoneInput
                          country={formik.values.selectorValueCode || "th"} // Default to Pakistan's country code
                          value={formik.values.phonenumber}
                          onChange={(value, country) => {
                            formik.setFieldValue("phonenumber", value);
                            formik.setFieldValue(
                              "selectorValueCode",
                              country.dialCode
                            );
                          }}
                          inputStyle={{ width: "100%", height: "45px" }}
                        />
                        <ErrorMessage
                          name="phonenumber"
                          component="div"
                          className="error-message text-danger"
                        />
                      </div>
                    )}
                    <div className="form-group">
                      <label>{t("password")}</label>
                      <div className="relative w-full">
                        <Field
                          type={showPassword ? "text" : "password"}
                          className="form-control form-control-light w-full pr-10" // Add padding-right to make space for the icon
                          placeholder={t("password")}
                          name="password"
                        />
                        <span
                          className="absolute inset-y-0 right-0 flex items-center pr-3"
                          onClick={togglePasswordVisibility}
                          style={{ cursor: "pointer" }}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </span>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="error-message text-danger"
                      />
                    </div>
                    <div className="form-group">
                      <Link to="/forgotpassword" className="forgot-password">
                        {t("forgotPassword")}
                      </Link>
                      <br />
                      <div
                        onClick={() => sendOtp(formik)}
                        style={{ cursor: "pointer" }}
                        className="forgot-password"
                      >
                        {errorMsg === "Need to verify first"
                          ? "Resend Code."
                          : ""}
                      </div>
                    </div>
                    <button
                      type="submit"
                      style={{ width: "30%" }}
                      className="btn-custom secondary btn-block"
                      onClick={() => postData(formik)}
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <div className="spinner-border" role="status"></div>
                      ) : (
                        t("login")
                      )}
                    </button>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {error ? (
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "space-between",
                          width: "80%",
                          backgroundColor: "#FF3131",
                          color: "white",
                          padding: "10px 20px 10px 20px",
                          borderRadius: "5px",
                          alignItems: "center",
                        }}
                      >
                        <span>{error ? `${t(errorMsg)}` : ""}</span>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            justifyContent: "center",
                            border: "white 2px solid",
                            borderRadius: "30px",
                            width: "40px",
                            backgroundColor: "#FF3131",
                            height: "40px",
                          }}
                          onClick={() => {
                            setError(false);
                          }}
                        >
                          <p
                            style={{
                              color: "white",
                              alignItems: "center",
                              marginTop: "3px",
                            }}
                          >
                            x
                          </p>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <p className="text-center mt-3 mb-0">
                    {t("createAnAcresAccount")}{" "}
                    <Link to="/register">{t("register")}</Link>{" "}
                  </p>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
      <div className="acr-auth-bg">
        <Slider className="acr-auth-bg-slider acr-cs-bg-slider" {...settings}>
          {images.map((item, i) => (
            <div key={i}>
              <div
                className="acr-cs-bg-item bg-cover bg-center"
                style={{
                  backgroundImage:
                    "url(" + process.env.PUBLIC_URL + "/" + item.img + ")",
                }}
              >
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Content;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip, Dropdown, NavLink } from "react-bootstrap";
import Item from "antd/es/list/Item";
import { useTranslation } from "react-i18next";
import defaultpic from "../../../assets/DefaultPic/profile.png";
import BASE_URL from "../../../baseUrl";

const gallerytip = <Tooltip>Gallery</Tooltip>;
const bedstip = <Tooltip>Beds</Tooltip>;
const bathstip = <Tooltip>Bathrooms</Tooltip>;
const areatip = <Tooltip>Square Feet</Tooltip>;

const Recentlistings = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const SubmitlistingData = async () => {
    const resposne = await fetch(`${BASE_URL}/submitlisting/lastsubmit`);
    const data = await resposne.json();
    setData(data.result);
  };

  useEffect(() => {
    SubmitlistingData();
  }, []);

  return (
    <div className="section-list">
      <div className="container">
        <div className="section-title-wrap section-header">
          <h5 className="custom-primary">{t("find_home2")}</h5>
          <h2>{t("Recent Listings")}</h2>
        </div>
        <div className="row">
          <div className="col-lg-8 mb-3 h-fit">
            {data?.slice(0, 4)?.map((item, i) => (
              <div key={i} className="listing listing-list Listing_side">
                <div className="listing-thumbnail listingMainImage w-[30%]">
                  <Link to={`/listing-details/${item?._id}`}>
                    <img
                      src={`${
                        item.Gallery.file[0] ? item.Gallery.file[0] : ""
                      }`}
                      alt="listing"
                      style={{
                        height: "89%",
                        width: "250px",
                        objectFit: "cover",
                      }}
                    />
                  </Link>
                  <div className="listing-badges classBadge">
                    {item?.star === true ? (
                      <span className="listing-badge featured">
                        {" "}
                        <i className="fas fa-star" />{" "}
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.BasicInformation.status
                      .split(", ")
                      .includes("For Sale") ? (
                      <span className="listing-badge sale">{t("On Sale")}</span>
                    ) : (
                      ""
                    )}
                    {true ? (
                      // <span className="listing-badge pending">
                      //   {" "}
                      //   {t("Pending")}
                      // </span>
                      <></>
                    ) : (
                      ""
                    )}
                    {item?.BasicInformation.status
                      .split(", ")
                      .includes("Rental") ? (
                      <span className="listing-badge rent"> {t("Rental")}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="listing-body myclassesMargin w-[70%]"
                  style={{ marginTop: "20px" }}
                >
                  <div className="listing-author">
                    <img
                      src={`${
                        item.Admin
                          ? item?.Admin?.pic
                          : item.Author
                          ? item?.Author?.pic
                          : defaultpic
                      }`}
                      alt="author"
                      width={50}
                      height={50}
                      style={{ borderRadius: "100%" }}
                    />
                    <div className="listing-author-body">
                      <p>
                        {" "}
                        <Link to="#">{item?.Author?.name}</Link>{" "}
                      </p>
                      <span className="listing-date">
                        {item?.createdAt.split("T")[0]}
                      </span>
                    </div>
                  </div>
                  <h5 className="listing-title">
                    {" "}
                    {item?.BasicInformation.name && (
                      <Link
                        to={`/listing-details/${item?._id}`}
                        title={item?.BasicInformation.name}
                      >
                        {item?.BasicInformation.name}
                      </Link>
                    )}
                  </h5>
                  {
                    item?.BasicInformation?.originalprice && (
                  <span className="listing-price-left text-[10px]">
                    Original Price : {' '}
                    {Number(item?.BasicInformation.originalprice.toLocaleString()) || ''}
                  </span>
                    )
                  }
                  <p className="listing-text myclassText">
                    {item?.BasicInformation.description.length > 70
                      ? item?.BasicInformation.description.slice(0, 70) + "..."
                      : item?.BasicInformation.description}
                  </p>

                  <div className="acr-listing-icons-left">
                    <OverlayTrigger overlay={bedstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bedroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.beds}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={bathstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bathroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.bathrooms}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={areatip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-ruler" />
                        <span className="acr-listing-icon-value">
                          {item?.BasicInformation.space} SQM
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div>
                    <p className="listing-price-left text-end " style={{fontSize:"25px"}}>
                    {item?.BasicInformation?.listingType == "Rent" ? (
                      <>
                        {"Rental Price "+
                        item.BasicInformation.currency +
                          Number(
                            item.BasicInformation.rentalPrice
                          ).toLocaleString()}
                      </>
                    ) : (
                      <>
                        {"Selling Price "+
                        item.BasicInformation.currency +
                          Number(item.BasicInformation.price).toLocaleString()}
                      </>
                    )}
                    </p>
                  </div>
                  <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                  <div className="listing-gallery-wrapper">
                    <Link
                      to={`/listing-details/${item?._id}`}
                      className="btn-custom btn-sm secondary"
                    >
                      {t("viewDetails")}
                    </Link>
                  </div>
                  <div style={{ marginBottom: "10px" }} >
                    <span>
                      <b>{t("Country")}</b>:
                      {item?.Location?.country == "undefined" ||
                      item?.Location?.country == ""
                        ? "Thailand"
                        : item?.Location?.country}
                    </span>
                    {item?.Location?.provice == "undefined" ||
                    item?.Location?.provice == "" ? (
                      "Province:Bangkok"
                    ) : (
                      <span style={{ marginLeft: "10px" }}>
                        <b> {t("Province")}</b> {item?.Location?.provice}
                      </span>
                    )}
                  </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="col-lg-4 mb-3">
            {data.slice(2, 3).map((item, i) => (
              <div key={i} className="listing listing-right">
                <div className="listing-thumbnail">
                  <Link
                    className="flex justify-center items-center bg-transparent hover:bg-transparent"
                    to={`/listing-details/${item?._id} `}
                  >
                    <img
                      src={`${item?.Gallery.file[0]}`}
                      alt="listing"
                      className="w-[100%] h-[270px] object cover"
                    />
                  </Link>
                  <div className="listing-badges">
                    {item?.star === true ? (
                      <span className="listing-badge featured">
                        {" "}
                        <i className="fas fa-star" />{" "}
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.sale === true ? (
                      <span className="listing-badge sale">{t("On Sale")}</span>
                    ) : (
                      ""
                    )}
                    {item?.pending === true ? (
                      <span className="listing-badge pending">
                        {" "}
                        {t("Pending")}
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.rental === true ? (
                      <span className="listing-badge rent"> {t("Rental")}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="listing-body">
                  <div className="listing-author">
                    <img
                      src={item.Admin?.pic || item.Author?.pic || defaultpic}
                      alt="author"
                    />
                    <div className="listing-author-body">
                      <p>
                        {" "}
                        <Link to="#">{item?.Author?.name}</Link>{" "}
                      </p>
                      <span className="listing-date">
                        {item?.createdAt.split("T")[0]}
                      </span>
                    </div>
                  </div>
                  <h5 className="listing-title">
                    {" "}
                    <Link
                      to={`/listing-details/${item?._id}`}
                      title={item?.BasicInformation.name}
                    >
                      {item?.BasicInformation.name}
                    </Link>{" "}
                  </h5>
                  {
                    item?.BasicInformation?.originalprice && (
                  <span className="listing-price-left text-[10px]">
                    Original Price : {' '}
                    {Number(item?.BasicInformation.originalprice.toLocaleString()) || ''}
                  </span>
                    )
                  }
                  <p className="listing-text">
                    {item?.BasicInformation.description}
                  </p>
                  <div className="acr-listing-icons">
                    <OverlayTrigger overlay={bedstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bedroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.beds}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={bathstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bathroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.bathrooms}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={areatip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-ruler" />
                        <span className="acr-listing-icon-value">
                          {item?.BasicInformation.space} SQM
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                    <div>
                      <p className="listing-price-left text-end" style={{fontSize:"25px"}}>
                      {item?.BasicInformation?.listingType == "Rent" ? (
                      <>
                        {"Rental Price "+
                        item.BasicInformation.currency +
                          Number(
                            item.BasicInformation.rentalPrice
                          ).toLocaleString()}
                      </>
                    ) : (
                      <>
                        {"Selling Price "+
                        item.BasicInformation.currency +
                          Number(item.BasicInformation.price).toLocaleString()}
                      </>)}
                      </p>
                    </div>
                  <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                  <div className="listing-gallery-wrapper">
                    <Link
                      to={`/listing-details/${item?._id}`}
                      className="btn-custom btn-sm secondary"
                    >
                      {t("viewDetails")}
                    </Link>
                  </div>
                  <div style={{ marginBottom: "10px" }} className="ms-2 text-end text-[10px]">
                    <span>
                      <b>{t("Country")}</b>:
                      {item?.Location?.country == "undefined" ||
                      item?.Location?.country == ""
                        ? "Thailand"
                        : item?.Location?.country}
                    </span>
                    {item?.Location?.provice == "undefined" ||
                    item?.Location?.provice == "" ? (
                      "Province:Bangkok"
                    ) : (
                      <span style={{ marginLeft: "10px" }}>
                        <b> {t("Province")}</b> {item?.Location?.provice}
                      </span>
                    )}
                  </div>
                  </div>
                </div>
              </div>
            ))}
            {data.slice(3, 4).map((item, i) => (
              <div key={i} className="listing listing-right">
                <div className="listing-thumbnail">
                  <Link
                    className="flex justify-center items-center bg-transparent hover:bg-transparent"
                    to={`/listing-details/${item?._id}`}
                  >
                    <img
                      src={`${item?.Gallery.file[0]}`}
                      alt="listing"
                      className="w-[100%] h-[270px] object cover"
                    />
                  </Link>
                  <div className="listing-badges">
                    {item?.star === true ? (
                      <span className="listing-badge featured">
                        {" "}
                        <i className="fas fa-star" />{" "}
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.sale === true ? (
                      <span className="listing-badge sale">{t("On Sale")}</span>
                    ) : (
                      ""
                    )}
                    {item?.pending === true ? (
                      <span className="listing-badge pending">
                        {" "}
                        {t("Pending")}
                      </span>
                    ) : (
                      ""
                    )}
                    {item?.rental === true ? (
                      <span className="listing-badge rent"> {t("Rental")}</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="listing-body">
                  <div className="listing-author">
                    <img
                      src={item.Admin?.pic || item.Author?.pic || defaultpic}
                      alt="author"
                    />
                    <div className="listing-author-body">
                      <p>
                        {" "}
                        <Link to="#">{item?.Author?.name}</Link>{" "}
                      </p>
                      <span className="listing-date">
                        {item?.createdAt.split("T")[0]}
                      </span>
                    </div>
                  </div>
                  <h5 className="listing-title">
                    {" "}
                    <Link
                      to={`/listing-details/${item?._id}`}
                      title={item?.BasicInformation.name}
                    >
                      {item?.BasicInformation.name}
                    </Link>{" "}
                  </h5>
                  {
                    item?.BasicInformation?.originalprice && (
                  <span className="listing-price-left text-[10px]">
                    Original Price : {' '}
                    {Number(item?.BasicInformation.originalprice.toLocaleString()) || ''}
                  </span>
                    )
                  }
                  <p className="listing-text">
                    {item?.BasicInformation.description}
                  </p>
                  <div className="acr-listing-icons">
                    <OverlayTrigger overlay={bedstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bedroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.beds}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={bathstip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-bathroom" />
                        <span className="acr-listing-icon-value">
                          {item?.Details.bathrooms}
                        </span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger overlay={areatip}>
                      <div className="acr-listing-icon">
                        <i className="flaticon-ruler" />
                        <span className="acr-listing-icon-value">
                          {item?.BasicInformation.space} SQM
                        </span>
                      </div>
                    </OverlayTrigger>
                  </div>
                  <div>
                      <p className="listing-price-left text-end" style={{fontSize:"25px"}}>
                      {item?.BasicInformation?.listingType == "Rent" ? (
                      <>
                        {"Rental Price "+
                        item.BasicInformation.currency +
                          Number(
                            item.BasicInformation.rentalPrice
                          ).toLocaleString()}
                      </>
                    ) : (
                      <>
                        {"Selling Price "+
                        item.BasicInformation.currency +
                          Number(item.BasicInformation.price).toLocaleString()}
                      </>
                    )}
                      </p>
                    </div>
                  <div style={{display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                  <div className="listing-gallery-wrapper">
                    <Link
                      to={`/listing-details/${item?._id}`}
                      className="btn-custom btn-sm secondary"
                    >
                      {t("viewDetails")}
                    </Link>
                  </div>
                  <div style={{ marginBottom: "10px" }} className="ms-2 text-end text-[10px]">
                    <span>
                      <b>{t("Country")}</b>:
                      {item?.Location?.country == "undefined" ||
                      item?.Location?.country == ""
                        ? "Thailand"
                        : item?.Location?.country}
                    </span>
                    {item?.Location?.provice == "undefined" ||
                    item?.Location?.provice == "" ? (
                      "Province:Bangkok"
                    ) : (
                      <span style={{ marginLeft: "10px" }}>
                        <b> {t("Province")}</b> {item?.Location?.provice}
                      </span>
                    )}
                  </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recentlistings;
